<template>
  <div class="min-h-screen h-full">
    <TsHeader data-testid="header" />
    <TsSection>
      <TsBreadcrumb class="hidden md:block py-2" :data="breadcrumb_data" />
      <NuxtLinkWithLocale to="/" v-if="routeName === 'account'">
        <TsRow append-class="m-0 mb-0 flex items-center sm:hidden my-2 relative right-2 md:hidden">
          <TsIcon name="iconamoon:arrow-left-2-duotone" color="#666666" size="1.5rem"
            class="cursor-pointer text-secondary-black mt-0" />

          <TsTypography appendClass="mb-0 block text-base text-black text-secondary-black" :text="useTranslation('back','Rug')" />
        </TsRow>
      </NuxtLinkWithLocale>
      <NuxtLinkWithLocale to="/account" v-else>
        <TsRow append-class="m-0 mb-0 flex items-center sm:hidden my-2 relative right-2 md:hidden">
          <TsIcon name="iconamoon:arrow-left-2-duotone" color="#666666" size="1.5rem"
            class="cursor-pointer text-secondary-black mt-0" />

          <TsTypography appendClass="mb-0 block text-base text-black text-secondary-black" :text="useTranslation('backToMyAccount','Terug naar mijn account')" />
        </TsRow>
      </NuxtLinkWithLocale>
      <TsRow>
        <TsColumn v-if="!isMobile" append-class="max-w-max">
          <TsCard append-class="w-[300px] bg-white border-[#DEEFFF] p-0"
            header-class="border-b border-[#DEEFFF] py-5 px-4" body-class="py-3"
            footer-class="border-t border-[#DEEFFF] py-5 px-4 flex justify-center" no-shadow>
            <template #header>
              <div class="flex items-center gap-2">
                <NuxtImg src="/images/hello-wave-icon.png" alt="hello-wave" width="24" height="24" />

                <TsTypography as="h2" append-class="mb-0">
                  {{ useTranslation("hello", "Hello,") }}
                  {{ authStore.user?.first_name }}
                </TsTypography>
              </div>

              <TsTypography data-testid="account-welcome-message-text">{{
                useTranslation(
                  "welcomeBack",
                  "Welcome back! Discover new tools."
                )
              }}</TsTypography>
            </template>
            <template #content>
              <div v-for="links in account_links" :key="links">
                <div v-for="item in links" :key="item.label_key" :class="twMerge(
                  'relative',
                  localPath(item.link) === route.path &&
                  'before:content-[\'\'] before:bg-primary before:rounded-r-lg before:absolute before:top-0 before:left-0 before:w-[5px] before:h-full bg-[#F6F9FE]'
                )
                  ">
                  <NuxtLinkWithLocale :to="item.link" :data-testid="item['data-testid']" :class="twMerge(
                    'w-full relative flex items-center px-4 py-3 font-semibold text-natural-grey',
                    localPath(item.link) === route.path && 'text-primary',
                    !item.show && 'hidden'
                  )
                    ">
                    <span :class="twMerge(
                      'mr-2 flex items-center',
                      localPath(item.link) === route.path && 'text-info'
                    )
                      ">
                      <Icon :name="item.icon" size="20"/>
                    </span>

                    <span>{{ useTranslation(item.label_key, item.label_default_value) }}</span>

                    <TsTag v-if="item.isNew" label="New" variant="info" append-class="font-semibold ml-3" size="xs"
                      rounded />

                    <span class="ms-auto flex items-center">
                      <Icon name="flowbite:angle-right-outline" size="20" />
                    </span>
                  </NuxtLinkWithLocale>
                </div>

                <TsDivider append-class="my-3 before:border-[#DEEFFF]" />
              </div>

              <NuxtLinkWithLocale to="/faq" :class="twMerge(
                'w-full relative flex items-center px-4 py-3 font-semibold text-natural-grey'
              )
                " data-testid="account-faq-link">
                {{ useTranslation("faq", "Veelgestelde vragen") }}
              </NuxtLinkWithLocale>

              <NuxtLinkWithLocale to="/about-us" :class="twMerge(
                'w-full relative flex items-center px-4 py-3 font-semibold text-natural-grey'
              )
                " data-testid="account-about-us-link">
                {{ useTranslation("aboutUs", "Over Ons") }}
              </NuxtLinkWithLocale>

              <!-- <NuxtLink
                to="#"
                :class="
                  twMerge(
                    'w-full relative flex items-center px-4 py-3 font-semibold text-natural-grey'
                  )
                "
              >
                Terms of use
              </NuxtLink>

              <NuxtLink
                to="#"
                :class="
                  twMerge(
                    'w-full relative flex items-center px-4 py-3 font-semibold text-natural-grey'
                  )
                "
              >
                Customer Policies
              </NuxtLink>

              <NuxtLink
                to="#"
                :class="
                  twMerge(
                    'w-full relative flex items-center px-4 py-3 font-semibold text-natural-grey'
                  )
                "
              >
                Useful Links
              </NuxtLink> -->
            </template>
            <template #footer>
              <TsButton :label="useTranslation('signOut', 'Afmelden')" icon="material-symbols:logout"
                @click="useAuthStore().signOut()" variant="danger"
                append-class="p-0 border-0 bg-transparent hover:bg-transparent hover:text-danger" icon-class="size-5"
                outlined data-testid="account-signout-button" />
            </template>
          </TsCard>
        </TsColumn>
        <TsColumn append-class="is-12 lg:is-9">
          <slot />
        </TsColumn>
      </TsRow>
      <div v-if="!isMobile" class="text-center pt-6">
        <TsRow isCentered>
          <TsCustomerSupport />
        </TsRow>
      </div>
    </TsSection>

    <TsProductActions v-if="runtimeConfig.public.featureFlags.enableCTAAddToTrolleyBuyNow" />

    <TsProductActionsv2 v-else-if="runtimeConfig.public.featureFlags.enableCTACollectionDelivery" />
    <TsFooter data-testid="footer" />
  </div>
</template>

<script setup>
import { twMerge } from "tailwind-merge";
import { ref } from "vue";

const localPath = useLocalePath();
const authStore = useAuthStore();
const route = useRoute();
const accountStore = useAccountStore();
const runtimeConfig = useRuntimeConfig();
const savelistStore = useSavelistStore();
const routeName = ref("");
const localePath = useLocalePath();
const signOut = useTranslation("signOut", "Sign out")

// const signOut = useTranslation("signOut", "Sign out");


const { isMobile } = useDevice();

onMounted(() => {
  if (!savelistStore.fetching_in_progress) {
    savelistStore.getUserSaveLists();
  }
});

const breadcrumb_data = computed(() => {
  routeName.value = route.fullPath.split("/");

  let breadcrumb_links = [
    {
      text: `${useTranslation('home', 'Thuis')}`,
      link: "/",
    },
    {
      text: `${useTranslation('myAccount', 'Mijn account')}`,
      link: "/account",
    },
  ];

  if (routeName.value[routeName.value.length - 1] === 'account') {
    return breadcrumb_links;
  }

  // const item = {
  //   text: routeName.value[routeName.value.length - 1] === "save-list" ? "Saved List" : routeName.value[routeName.value.length - 1] === "message" ? 'Message Center' : useCapitalize(routeName.value[routeName.value.length - 1]),
  // }

  const text = route.path.split('/').at(-1).replace('-', ' ').split(' ').map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(' ');
  const toCamelCase=(text)=> {
    // Split the string by spaces
    const words = text.toLowerCase().split(" ");

    // If it's a single word, just return it in lowercase
    if (words.length === 1) {
      return words[0];
    }

    // Otherwise, capitalize the first letter of each word except the first word
    for (let i = 1; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words together and return
    return words.join("");
  }

  const item = { text: useTranslation(toCamelCase(text),text) };

  // console.log(route.path.split('/').at(-1).replace('-', ' ').split(' ').map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(' '), 'route')

  breadcrumb_links.push(item);

  return breadcrumb_links;

});

const computeOrderAgainElseCondition = () => {
  return routeName.value[routeName.value.length - 1].includes('order-again?orderId')
    ? "Order Again"
    : useCapitalize(routeName.value[routeName.value.length - 1]);
}

const account_links = [
  [
    {
      label_key: "profile",
      label_default_value: "Profile",
      icon: "fa-regular:user",
      link: "/account/profile",
      "data-testid": "account-profile-link",
      isNew: false,
      show: true,
    },
  ],
  [
    {
      label_key: "orders",
      label_default_value: "Orders",
      icon: "f7:cube-box",
      link: "/account/orders",
      "data-testid": "account-orders-link",
      isNew: false,
      show: true,
    },
    {
      label_key: "orderAgain",
      label_default_value: "Opnieuw bestellen",
      icon: "ic:sharp-replay",
      link: "/account/order-again",
      'data-testid': 'account-order-again-link',
      isNew: false,
      show: runtimeConfig.public.featureFlags.accountOrderAgainBarVisible,
    },
    {
      label_key: "saveList",
      label_default_value: "Lijst opslaan",
      icon: "material-symbols:bookmark-outline",
      link: "/account/save-list",
      "data-testid": "account-save-list-link",
      isNew: false,
      show: true,
    },
    {
      label_key: "stockNotification",
      label_default_value: "Stock Notification",
      icon: "mdi:bell-outline",
      link: "/account/stock-notification",
      "data-testid": "account-stock-notification-link",
      isNew: true,
      show: runtimeConfig.public.featureFlags.accountStockNotifyBarVisible,
    },
    {
      label_key: "messageCenter",
      label_default_value: "Bericht Centrum",
      icon: "charm:message",
      link: "/account/message",
      'data-testid': 'account-message-center-list-link',
      show: runtimeConfig.public.featureFlags.accountMessage,
      isNew: false,
    },
  ],
  [
    {
      label_key: "proCard",
      label_default_value: "PRO Card",
      icon: "circum:credit-card-1",
      link: "/account/pro-card",
      "data-testid": "account-pro-card-link",
      isNew: false,
      show: authStore?.user?.account_type === "Pro Card" || authStore?.user?.account_type === "Pro Card Zero" || runtimeConfig.public.featureFlags.accountProCardBarVisible,
    },
    {
      label_key: "addresses",
      label_default_value: "Addresses",
      icon: "carbon:location",
      link: "/account/addresses",
      "data-testid": "account-addresses-link",
      isNew: false,
      show: true,
    },
    {
      label_key: "defaultBranch",
      label_default_value: "Standaard Tak",
      icon: "mynaui:store",
      link: "/account/branch",
      "data-testid": "account-default-branch-link",
      isNew: false,
      show: true,
    },
  ],
  [
    {
      label_key: "myOffers",
      label_default_value: "My Offers",
      icon: "ic:outline-local-offer",
      link: "/samples/offers",
      "data-testid": "account-my-offers-link",
      isNew: false,
      show: false,
    },
    {
      label_key: "accountPreferences",
      label_default_value: "AccountVoorkeuren",
      icon: "weui:setting-outlined",
      link: "/account/preferences",
      "data-testid": "account-preferences-link",
      isNew: false,
      show: true,
    },
    {
      label_key: "requestCatalogue",
      label_default_value: "Request Catalogue",
      icon: "ion:book-outline",
      link: "",
      'data-testid': 'account-request-catalogue-link',
      isNew: false,
    },
    {
      label_key: "savedBlogs",
      label_default_value: "Saved Blogs",
      icon: "tdesign:catalog",
      link: "",
      'data-testid': 'account-saved-blogs-link',
      isNew: false,
    },
  ],
  [
    {
      label_key: "needHelp",
      label_default_value: "Need help",
      icon: "streamline:customer-support-1",
      link: "",
      "data-testid": "account-need-help-link",
      isNew: false,
      show: runtimeConfig.public.featureFlags.accountNeedHelpBarVisible,
    },
  ],
];
</script>
